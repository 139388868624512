import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import { reviewsData } from "../data/reviews.data";
import { setInnerHTMLToEl, setInnerTextToEl } from "./helpers";

const $reviewsContainer = document.getElementById('reviews_container');

class ReviewItem {
  constructor(data, $parent) {
    this.item = document.getElementById('review_template').content.cloneNode(true);
    this.$parent = $parent;

    setInnerTextToEl(this.item, '.feedback__username', data.username)
    setInnerTextToEl(this.item, '.feedback__project_number', data.project)
    setInnerHTMLToEl(this.item, '.feedback__text', data.text)
    this._setImage(data.img);

    this.appendToParent($parent);
  }

  _setImage(value) {
    const $el = this.item.querySelector('.feedback__user_pic img');
    $el.setAttribute('src', value);
  }

  appendToParent() {
    this.$parent.append(this.item)
  }
}

export function initReviews() {
  for (const reviewData of reviewsData) {
    new ReviewItem(reviewData, $reviewsContainer)
  }

  initFeedbackSwiper();
}

function initFeedbackSwiper() {
  new Swiper('.feedback-swiper', {
    slidesPerView: 'auto',
    modules: [Navigation, Pagination],
    pagination: {
      el: '.feedback__pagination',
      clickable: true,
    },
    centeredSlides: true,
    spaceBetween: 50,
    navigation: {
      nextEl: '.swiper-arrow_right',
      prevEl: '.swiper-arrow_left',
    },
  });
}
