import { initHeader } from "./header";
import { initCatalog } from "./catalog";
import { initFaqItems } from "./faq";
import 'swiper/swiper.css'
import 'swiper/modules/pagination.css'
import {RequestForm} from "./form";
import { modalInstance } from "./modal";
import {initMaskito} from "./mask";
import {initReviews} from "./reviews";


initHeader();
initCatalog();
initFaqItems();
initMaskito();
initReviews();

new RequestForm('main_form', () => {
  modalInstance.openThanks();
}, false)