export const reviewsData = [
  {
    img: new URL(`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Ccircle fill='%238585cc' r='25' cy='25' cx='25'/%3E%3Ctext fill='%23fff' font-family='sans-serif' font-size='20' dy='8' x='50%25' y='50%25' text-anchor='middle'%3EН %3C/text%3E%3C/svg%3E`, import.meta.url),
    username: 'Этинзон Наталья Евгеньевна',
    text: "Живем уже год в построенном доме от команды Группа Компаний Профессионалы. Теплый, качественный дом из керамического кирпича! Отдельное спасибо Тильниковой Ирине Вячеславовне за профессиональное отношение к работе и особое внимание ко всем просьбам и пожеланиям!!! Разработали персональный проект, подготовили весь пакет документов для ипотеки, одобрили без проблем. В процессе строительства подключили электричество, пробурили скважину и завели канализацию, все в одной компании. Успеха Вам! <br/><a target=\"_blank\" href=\"https://yandex.ru/maps/org/222119606283/reviews?reviews%5BpublicId%5D=f3af0ybte6ag9qzgke544jhgr4&utm_source=review\">Источник</a>",
    project: 'Строительство дома'
  },
  {
    img: new URL(`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Ccircle fill='%2350c0e6' r='25' cy='25' cx='25'/%3E%3Ctext fill='%23fff' font-family='sans-serif' font-size='20' dy='8' x='50%25' y='50%25' text-anchor='middle'%3EИ %3C/text%3E%3C/svg%3E`, import.meta.url),
    username: 'Иван Р.',
    text: 'Качественно проектируют и строят. Совместно делали два дома. Очень понравился подход. Поэтапно проектирование, визуализация и воплощение в коробке. У них очень толковый дизайнер и грамотный юрист,который очень хорошо провел мою сделку с землёй, ввёл в эксплуатацию дом и ответил на все волнующие вопросы. Рекомендую. <br/><a target="_blank" href=\"https://yandex.ru/maps/org/222119606283/reviews?reviews%5BpublicId%5D=6a8v8p6k5mh1pmc4ezk8071y5g&utm_source=review\">Источник</a>',
    project: 'Строительство дома'
  },
  {
    img: new URL(`/pics/reviews/9gQKevR6-zzoe6i9821ejUuAVTll3Z1M4Iqf1zD5L3VHyK13TNBf4zMW_wAHKQh3ZCPxkZU4HlL1rwgNyKGY5WZT.jpg`, import.meta.url),
    username: 'Шабалина Надежда Александровна',
    text: 'Начинается жаркая пора строительства, я так жду, когда растает снег и буду строить еще один дом на своем участке. Компания Ирина Тильникова мне уже построила мои первые 159 кв. метров быстро, качественно под мои запросы.Если вы не знаете куда обратиться, что бы и у вас сбылась мечта о вашем доме, напишите мне и получите дополнительную скидку и конечно гарантию, что ваш дом будут строить профессионалы. <br/><a target="_blank" href=\"https://vk.com/wall155250823_10426\">Источник</a>',
    project: 'Строительство дома'
  },
  {
    img: new URL(`/pics/reviews/pRb2lSkB4U7kJi1Y8-yijIngdPLP8mHY2w_vGYd7iio5_igyMtzECnKvOC10WpZl2HS2hxB2m2Ldk5zLUbHKG6f_.jpg`, import.meta.url),
    username: 'Морозова Мария',
    text: "Давно не писала про наш дом! Дом построен. Огромное спасибо Ирине Тильниковой - руководителю нашего проекта! Мы очень капризные заказчики. Ирина и ее команда всегда были на связи и все вопросы решали молниеносно, за что отдельное спасибо Все этапы строительства с нами согласовывались и любые изменения сразу обговаривались. Профессионализм на высшем уровне  Мы очень благодарны и счастливы, ведь у нас теперь есть замечательный дом! Наша мечта!\nА к Новому году наша команда сделала нам сюрприз (мы не ожидали, если честно) ...<br/><a target=\"_blank\" href=\"https://vk.com/wall57596233_4309\">Источник</a>",
    project: 'Строительство дома'
  },
]