import {Maskito} from '@maskito/core';
import {
  maskitoAddOnFocusPlugin,
  maskitoCaretGuard,
  maskitoPrefixPostprocessorGenerator,
  maskitoRemoveOnBlurPlugin,
} from '@maskito/kit';

const maskitoOptions = {
  mask: [
    '+',
    /\d/,
    ' ',
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  postprocessors: [
    // non-removable country prefix
    maskitoPrefixPostprocessorGenerator('+7 '),
  ],
  plugins: [
    maskitoAddOnFocusPlugin('+7 '),
    maskitoRemoveOnBlurPlugin('+7 '),
    // Forbids to put caret before non-removable country prefix
    // But allows to select all value!
    maskitoCaretGuard((value, [from, to]) => [
      from === to ? '+7 '.length : 0,
      value.length,
    ]),
  ],
};


export function initMaskito(selector = '[name=phone]') {
  const element = document.querySelector(selector);
  return new Maskito(element, maskitoOptions);
}
