export const questionsData = [
  {
    question: 'Сколько нужно денег, что бы построить дом?',
    answer: 'В среднем по региону строительство дома обходится в 3 млн. рублей. Цена зависит объема квадратных метров и от материалов постройки'
  },
  {
    question: 'Влияние архитектуры на стоимость',
    answer: `<b>Чем больше углов, чем больше выступов, тем:</b>
<li>сложнее строить, больше шансов на ошибку</li>
<li>больше не только расход материалов, но и количество отходов</li>
<li>больше возможностей для промерзания (углы промерзают сильнее)</li>
<li>Усложняются не только стены, но и фундамент и конфигурация кровли (дороже и сложнее строить)</li>
<b>Чем сложнее кровля, тем больше для вас проблем:</b>
<li>Ендова – это самое проблемное место в любой кровле. Особенно это касается металлочерепицы. Это означает, что каждая лишняя ендова для вас может означать потенциальную проблему с протечкой или с промерзанием.</li>
<li>Кроме того, каждый излом кровли ведет к повышенному расходу материалов. Особенно актуально для металлочерепицы. Отходы могут составить до 40% от общей площади.</li>
<li>Чем сложнее конфигурация кровли, тем дороже ее монтаж и опять же – тем больше доп.материалов потребуется.</li>
Фасад дома так же имеет свою цену, поэтому нужно понимать, какой бюджет закладывается на этот этап строительства. Можно обложить кирпичом, а можно обделать фасадной штукатуркой понравившиеся цвета, но цена естественно намного отличается
Окна тоже во многом влияют на цену дома (их кол-во, размер и качество)
`
  },
  {
    question: 'Сроки строительства дома',
    answer: 'Сроки строительства дома от 90-120 дней, в зависимости от сложности'
  },
  {
    question: 'Какая площадь дома оптимальна?',
    answer: 'Чтобы проще было определиться с площадью дома, нужно представлять, для каких целей он будет использоваться. Для сезонного проживания достаточно будет ограничиться небольшим дачным домиком. А вот если вы предусматриваете постоянное проживание в доме, то лучшим вариантом будет дом площадью от 100 кв. м.'
  },
  {
    question: 'Сколько этажей лучше? 1 или 2. плюсы и минусы',
    answer: `В одноэтажном доме будет больше площадь фундамента и крыши. А это одни из самых затратных этапов строительства. При современных методах строительства они дают прибавку около 20% с двухэтажным. А вот стены у двух этажного дома хоть и больше, но без учета внешней отделки, окон и высотных работ не сильно дороже.
    <p>Без чего не обходится двухэтажный проект — без лестницы и дополнительно санузла на втором этаже. А это существенная прибавка к бюджету стройки.</p>
    <p>Плюсы и минусы одноэтажного дома по сравнению с двухэтажным.</p>
    <p>Плюсы:</p>
    <li>Нет лестницы, удобно для пожилых и безопасно детей</li>
    <li>Больше полезной жилой площади (опять из-за лестницы и подходов к ней)</li>
    <li>Проще обслуживание, нет высотных работ</li>
    <li>Меньше теплопотери, зимой теплее, летом прохладнее</li>
    <p>Минусы:</p>
    <li>Нет балкона</li>
    <li>Больше площади участка уходит под дом</li>
    <li>Цена дороже, чем двухэтажный</li>
`
  },
  {
    question: 'Когда уместно делать подвал в доме?',
    answer: 'Подвал – это в первую очередь дополнительная полезная площадь. Подвал крайне необходим в качестве подсобного помещения или же выполнять функцию жилой комнаты, бильярдной, склада, котельной, мастерской, тренажерного зала. На такую подстройку стоит решиться, если планировка дома не может предусмотреть такое необходимое помещение по размерам.'
  },
  {
    question: 'Из какого материала строить дом? кратки й обзор +/-',
    answer: `
<b>Кирпичный дом</b>
<p>Плюсы:</p>
<li>высокая долговечность и прочность;</li>
<li>инертность к грызунам, насекомым и плесени;</li>
<li>устойчивость к огню;</li>
<li>материал пропускает воздух;</li>
<li>кирпич позволяет воплотить в реальность проект любой сложности.</li>
<p>Минусы:</p>
<li>необходимость тщательно подготавливать фундамент, так как кирпич – тяжелый материал, а кладку делают в несколько рядов;</li>
<li>невысокие теплоизоляционные свойства;</li>
<li>высокая трудоемкость работ и низкая скорость строительства;</li>
<li>кирпичный дом обходится дороже строительства из других материалов.</li>

<b>Газобетон</b>
<p>Плюсы:</p>
<li>высокая скорость строительства обеспечивается большими размерами блоков и их относительно небольшим весом;</li>
<li>невысокая теплопроводность, достигающаяся за счет пористой структуры;</li>
<li>высокая прочность на сжатие;</li>
<li>устойчивость к огню, микроорганизмам и вредителям;</li>
<li>экологичность;</li>
<li>невысокая цена.</li>
<p>Минусы:</p>
<li>высокое водопоглощение, которое может достигать 35%. Из-за этого снижается прочность, долговечность и теплоизоляционные качества. </li>
<li>невысокая прочность на изгиб, поэтому при неправильном монтаже со временем на стенах могут появиться трещины;</li>
<li>проблемы с крепежом, точнее особенности. Для крепления подойдут универсальные или винтовые дюбели, которые легко найти в продаже. Легкие предметы можно смело навешивать на обычные гвозди.</li>
<b>Пенобетон</b>
<p</p>>Плюсы:</p>
<li>неплохие тепло и звукоизоляционные качества;</li>
<li>небольшой вес и удобство в работе, высокая скорость строительства дома;</li>
<li>высокая плотность на сжатие;</li>
<li>устойчивость к огню, плесени, грызунам;</li>
<li>невысокая стоимость </li>
<p>Минусы:</p>
<li>небольшая прочность на изгиб. Прочность материала растет со временем, поэтому свежеизготовленные блоки использовать не стоит – с момента производства должно пройти не менее 28 дней;</li>
<li>набором прочности объясняется и небольшая усадка дома (2-4 мм на погонный метр);</li>
<li>не всегда идеальная геометрия.</li>
<b>Керамзитобетонные блоки</b>    
<p>Плюсы:</p>
<li>теплопроводность стен. очень хорошо держат тепло внутри себя, если конечно соблюдена технология, как производства, так и монтажа блоков. Это происходит благодаря пористому наполнителю – керамзиту.</li>
<li>небольшой вес </li>
<li>Несмотря на то, что керамзитобетон относительно легкий материал, его прочности и долговечности </li>
<li>стоимость по сравнению с другими материалами, причем как стоимость самих блоков, так и их кладки. Благодаря этому такие дома никогда не потеряют своей популярности среди застройщиков.</li>
<li>мало подвержены усадке.</li>
<li>Экологически чистые компоненты</li>
<li>хорошие звукоизолирующие свойства</li>
<li>очень легко монтируются, что положительно сказывается на стоимости работ по строительству дома в целом. </li>
<p>Минусы:</p>
<li>Пористость керамзитобетонных блоков является как плюсом, так же в свою очередь и минусом для дома. Не стоит использовать керамзитобетонные блоки без дополнительной защиты от воздействия окружающей среды</li>
<li>Из-за небольшого размера, в кладке будет очень много швов, которые, в свою очередь, очень хорошо пропускают холод внутрь дома.</li>
    `
  },
  {
    question: 'Из чего делать кровлю? металлочерепица, мягкая?',
    answer: `
<b>Металлочерепица</b>
<p>Плюсы:</p>
<li>металлочерепица не горит, соответственно обладает высокой пожарной безопасностью;</li>
<li>длительный срок службы без потери свойств;</li>
<li>высокая степень гидроизоляции.</li>
<p>Минусы:</p>
<li>высокая вероятность протечек на стыках листов, в местах соединения с дымоходами и вентиляционными трубами при недостаточно тщательном монтаже;</li>
<li>высокий расход материала на крышах со сложным профилем, вызванный необходимостью подрезать большое количество листов;</li>
<li>монтаж возможен только с использованием специальных инструментов;</li>
<li>необходимость в снегозадержателях: лавинообразный сход снега, что характерно для кровли из этого материала, может создать зону повышенной опасности под свесом;</li>
<li>сложная транспортировка: нередки ситуации, когда доставка металлочерепицы на какой-нибудь отдаленный, труднодоступный участок может удвоить ее стоимость.</li>
<b>Мягкая кровля</b>
<p>Плюсы:</p>
<li>гибкость, благодаря чему подходит для возведения кровли любой сложности и кривизны;</li>
<li>эффективное поглощение звуков, отличная теплоизоляция – такой тип кровли называют не только мягким, но и тихим, тёплым;</li>
<li>шероховатая поверхность, которая отлично задерживает снег;</li>
<li>богатый выбор расцветок и форм;</li>
<li>возможность монтажа без специализированного инструмента.</li>
<p>Минусы:</p>
<li>необходимость в регулярной очистке кровли – шероховатое покрытие удерживает не только снег, но и листья, грязь, мусор;</li>
<li>монтаж производится на сплошную обрешетку, что увеличивает стоимость кровельного пирога;</li>
<li>более высокая нагрузка на стропила, которые покрывают сплошной основой из фанеры, ОСП, других материалов. Такая конструкция имеет увеличенный вес;</li>
<li>невозможность монтажа при отрицательных температурах, ухудшается прочность склеивания.</li>
`
  },
  {
    question: 'Чем отапливать дом?',
    answer: 'Газовое отопление выгоднее, чем электрическое. Тем более если для постоянного проживания. Но оба варианта допустимы'
  },
  {
    question: 'Водоснабжение и водоотведение',
    answer: `
Есть два типа скважин для водоснабжения дома:
<b>Скважина</b>
<p>Плюсы скважины:</p>

<li>стабильный объем воды;</li>
<li>высокое качество воды;</li>
<li>не нужно регулярно ремонтировать.</li>
<p>Минусы скважины:</p>

<li>бурение — дорогостоящая процедура;</li>
<li>срок эксплуатации меньше</li>
<li>нужно использовать дополнительные дорогостоящие насосы.</li>
<li>Расход электричесва</li>

<b>Центральный водопровод</b>
<p>Минусы: </p>
<li>Если случился порыв — дом останется без воды.</li>
<li>Не всегда равномерный напор воды.</li>
<li>Ежемесячная плата за воду.</li>
<li>Качество воды нужно дополнительно очищать.</li>
<p>Плюсы</p>
<li>не ограниченный расход воды;</li>
<li>отсутствие необходимости устройства колодца или бурения скважины на участке дома;</li>
<li>за обслуживание водопровода отвечает «Водоканал»;</li>
<li>вода не перестанет течь при отключении электроэнергии</li>
`
  },
  {
    question: 'Как найти хорошего подрядчика. почему нужно строить с нами',
    answer: `
    <p>Поиска подрядчика – это отзывы о компании о проделанной работе, примеры выполненных работ(как общие, так и поэтапно), выбирать компанию с опытом, которая долгое время на рынке, ну и естественно отзывы друзей и знакомых, которые уже строились в данной компании</p>
    <p>Почему нужно строиться с нами : Производится полный этап строительства Вашего дома,(от поиска участка, до окончательного строительства дома) что позволит Вам сэкономить много времени и сил на поиски места для строительства.</p>
    <p>Гарантия качества выполненных работ, опыт работы более 5 лет, качественные материалы, высококвалифицированные сотрудники, проверка качества выполнения каждого этапа строительства с фото и видео отчетом заказчику, своевременные сроки выполнения работ</p>
   `
  },
]