import { RequestForm } from "./form";
import { initMaskito } from "./mask";

async function download(url, filename) {
  try {
    const data = await fetch(url)
    const blob = await data.blob()
    const objectUrl = URL.createObjectURL(blob)

    const link = document.createElement('a')

    link.setAttribute('href', objectUrl)
    link.setAttribute('download', filename)
    link.style.display = 'none'

    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
  } catch (e) {
    console.error(e);
  }
}

class Modal {
  constructor($el) {
    this.$el = $el;
    this.$thanksScreen = this.$el.querySelector('.modal__thanks-screen');
    this.$title = this.$el.querySelector('.modal__title');
    this.projectData = null;
    this.maskito = null;
    this.requestForm = new RequestForm('modal_form', this.onSendRequestCallback.bind(this), true)

    this._initClose();

    // this.openThanks();
  }

  async onSendRequestCallback() {
    await this.downloadDocument();
    this.setThanksScreenVisibility(true);
  }

  _initClose() {
    const $el = this.$el.querySelector('.modal__close-icon');

    $el.addEventListener('click', () => {
      this.close();
    })
  }

  setThanksScreenVisibility(isVisible = true) {
    if (isVisible) {
      this.$thanksScreen.classList.add('modal__thanks-screen_active');
    } else {
      this.$thanksScreen.classList.remove('modal__thanks-screen_active');
    }
  }

  openThanks() {
    this.$title.style.display = 'none'
    this.requestForm.hide();
    this.setThanksScreenVisibility(true)
    this.$el.classList.add('modal_visible')
    ym(93802001, 'reachGoal', 'thanksModal');
    setTimeout(() => { window.location.reload(); }, 3000)
  }

  open(document) {
    if (document) {
      this.projectData = document
      this.maskito = initMaskito('.modal [name=phone]');

      const houseNameInput = this.$el.querySelector('input[name="house-name"]'); // ищем скрытое поле в котором будет передаваться название проекта

      if (houseNameInput)
        houseNameInput.value = this.projectData.title; // вставляем это название

      if (localStorage.getItem('already_sent') === 'yes') {
        this.downloadDocument();
        return;
      }
    } else {
      this.projectData = null
    }

    this.$el.classList.add('modal_visible')
  }

  close() {
    this.$title.style.display = 'block'
    this.$el.classList.remove('modal_visible')
    this.requestForm.show();
    this.setThanksScreenVisibility(false)
    this.projectData = null;
    if (this.maskito) {
      this.maskito.destroy();
    }
  }

  async downloadDocument() {
    if (!this.projectData.id) {
      return;
    }

    try {
      await download(`/public/${this.projectData.id}.pdf`, `${this.projectData.id}_${this.projectData.title}`);
    } catch (e) {
      console.error(e);
    }
  }
}

const modalInstance = new Modal(document.getElementById('modal'));

export { Modal, modalInstance };

