import { projectsData } from "../data/projects.data";
import { modalInstance } from "./modal";

// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

// catalog
const $catalogParent = document.getElementById('catalog_items');

// pagination
const initialCount = 4;
let visibleCount = initialCount;

const catalogItems = {};

function temporaryStopSmoothScroll() {
  document.documentElement.style.scrollBehavior = 'auto';

  setTimeout(() => {
    document.documentElement.style.scrollBehavior = 'smooth';
  }, 500)
}

class CatalogItem {
  constructor(data, $parent, isVisible) {
    const template = document.getElementById('catalog_item')
    this.item = template.content.cloneNode(true);
    this.data = data;
    this.hiddenClassName = 'catalog-item_hidden';
    this.$root = this.item.querySelector('.catalog-item');
    this.$parent = $parent;

    this._setImages(data.images);
    this._setTitle(data.title);
    this._setType(data.type);
    this._setProps(data.props);
    this._initBtn();
    if (!isVisible) {
      this.hide();
    }
    this.appendToParent($parent);
  }

  _initBtn() {
    const $el = this.item.querySelector('.catalog-item__btn')
    $el.addEventListener('click', () => {
      modalInstance.open(this.data);
    })
  }

  _setImages(imagesData) {
    const $imagesRoot = this.item.querySelector('.catalog-item__images');
    const $el = this.item.querySelector('.catalog-item__images img');

    for (const imgI in imagesData) {
      if (Number(imgI) === 0) {
        $el.setAttribute('src', imagesData[imgI]);
        continue;
      }

      const newImage = $el.cloneNode();
      newImage.setAttribute('src', imagesData[imgI])
      $imagesRoot.append(newImage);
    }

    const swiper = new Swiper('.swiper', {
      slidesPerView: 1,
      modules: [Navigation, Pagination],
      pagination: {
        el: '.catalog-item__swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-arrow_right',
        prevEl: '.swiper-arrow_left',
      },
      on: {
        // вручную переключаем активные точки из-за непонятного глюка
        slideChange(swiper) {
          const dots = swiper.el.querySelectorAll('.swiper-pagination-bullet')
          for (const dotIndex in Array.from(dots)) {
            dots[dotIndex].classList.remove('swiper-pagination-bullet-active')
            if (+dotIndex === +swiper.activeIndex) {
              dots[dotIndex].classList.add('swiper-pagination-bullet-active')
            }
          }
        }
      }
    });
  }

  _setTitle(value) {
    const $el = this.item.querySelector('.catalog-item__name');
    $el.innerText = value;
  }

  _setType(value) {
    const $el = this.item.querySelector('.catalog-item__type');
    $el.innerText = value;
  }

  _setProps(props) {
    const $propParent = this.item.querySelector('.catalog-item__content-body');
    for (const prop of props) {
      new CatalogItemProp(prop, $propParent);
    }
  }

  hide() {
    if (!this.$root.classList.contains(this.hiddenClassName)) {
      this.$root.classList.add(this.hiddenClassName);
    }
  }

  show() {
    if (this.$root.classList.contains(this.hiddenClassName)) {
      this.$root.classList.remove(this.hiddenClassName);
    }
  }

  appendToParent() {
    this.$parent.append(this.item)
  }
}

class CatalogItemProp {
  constructor(data, $parent) {
    const template = document.getElementById('catalog_item_prop')
    this.item = template.content.cloneNode(true);

    this._setImage(data.img);
    this._setName(data.name);
    this.appendTo($parent);
  }

  _setImage(value) {
    const $el = this.item.querySelector('.catalog-item-prop__icon img');
    $el.setAttribute('src', value);
  }

  _setName(value) {
    const $el = this.item.querySelector('.catalog-item-prop__name');
    $el.innerText = value;
  }

  appendTo($el) {
    $el.append(this.item)
  }
}

function initShowMoreMechanism() {
  const $catalogShowMoreBtn = document.getElementById('catalog_show_more');
  let initialScroll;

  $catalogShowMoreBtn.addEventListener('click', () => {
    if (visibleCount < projectsData.length) {
      if (!initialScroll) {
        initialScroll = window.scrollY;
      }
      visibleCount = projectsData.length;
      $catalogShowMoreBtn.innerText = 'Скрыть';
      updateCatalogItemVisibility();
    } else {
      visibleCount = initialCount;
      $catalogShowMoreBtn.innerText = 'Загрузить еще';
      temporaryStopSmoothScroll();
      window.scrollTo({ top: initialScroll });
      updateCatalogItemVisibility();
    }
  })
}

initShowMoreMechanism();

function updateCatalogItemVisibility() {
  for (const [catalogItemIndex, catalogItem] of Object.entries(catalogItems)) {
    if (Number(catalogItemIndex) < visibleCount) {
      catalogItem.show();
    } else {
      catalogItem.hide();
    }
  }
}

export function initCatalog() {
  for (const catalogItemIndex in projectsData) {
    const isVisible = Number(catalogItemIndex) < visibleCount;
    catalogItems[Number(catalogItemIndex)] = new CatalogItem(projectsData[catalogItemIndex], $catalogParent, isVisible);
  }
}
