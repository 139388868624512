const icons = {
  metrics: new URL('/icons/metrics.svg', import.meta.url),
  floors: new URL('/icons/floors.svg', import.meta.url),
  projectNumber: new URL('/icons/project_number.svg', import.meta.url),
  rooms: new URL('/icons/rooms.svg', import.meta.url),
}

function getImgUrl(id, imgNumber) {
  return new URL(`/pics/projects/${id}/${imgNumber}.webp?width=421`, import.meta.url)
}

function getImgUrls(id, count) {
  return new Array(count).fill(1).map((img, i) => getImgUrl(id, i + 1))
}

export const projectsData = [
  {
    type: 'растущий',
    id: '001',
    title: 'Уют',
    images: [
      new URL('/pics/projects/001/1.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/001/2.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/001/3.webp?width=421&quality=100', import.meta.url)
    ],
    props: [
      {
        img: icons.metrics,
        name: '38.4 м2',
      },
      {
        img: icons.floors,
        name: 'Растущий дом',
      },
      {
        img: icons.rooms,
        name: '2 комнаты',
      },
      {
        img: icons.projectNumber,
        name: '001',
      },
    ]
  },
  {
    type: 'одноэтажный',
    id: '015',
    title: 'Изящность',
    images: [
      new URL('/pics/projects/015/1.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/015/2.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/015/3.webp?width=421&quality=100', import.meta.url)
    ],
    props: [
      {
        img: icons.metrics,
        name: '62.2 м2',
      },
      {
        img: icons.floors,
        name: '1 этаж',
      },
      {
        img: icons.rooms,
        name: '3 комнаты',
      },
      {
        img: icons.projectNumber,
        name: '015',
      },
    ]
  },
  {
    id: '004',
    type: 'растущий',
    title: 'Лёгкость',
    images: [
      new URL('/pics/projects/004/1.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/004/2.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/004/3.webp?width=421&quality=100', import.meta.url)
    ],
    props: [
      {
        img: icons.metrics,
        name: '63 м2',
      },
      {
        img: icons.floors,
        name: 'Растущий дом',
      },
      {
        img: icons.rooms,
        name: '1 комната',
      },
      {
        img: icons.projectNumber,
        name: '004',
      },
    ]
  },
  {
    id: '013',
    type: 'растущий',
    title: 'Горизонт',
    images: [
      new URL('/pics/projects/013/1.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/013/2.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/013/3.webp?width=421&quality=100', import.meta.url)
    ],
    props: [
      {
        img: icons.metrics,
        name: '69.3 м2',
      },
      {
        img: icons.floors,
        name: 'Растущий дом',
      },
      {
        img: icons.rooms,
        name: '2 комнаты',
      },
      {
        img: icons.projectNumber,
        name: '013',
      },
    ]
  },
  {
    id: '003',
    type: 'одноэтажный',
    title: 'Свобода',
    images: [
      new URL('/pics/projects/003/1.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/003/2.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/003/3.webp?width=421&quality=100', import.meta.url)
    ],
    props: [
      {
        img: icons.metrics,
        name: '90.1 м2',
      },
      {
        img: icons.floors,
        name: '1 этаж',
      },
      {
        img: icons.rooms,
        name: '2 комнаты',
      },
      {
        img: icons.projectNumber,
        name: '003',
      },
    ]
  },
  {
    id: '010',
    type: 'одноэтажный',
    title: 'Добро',
    images: [
      new URL('/pics/projects/010/1.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/010/2.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/010/3.webp?width=421&quality=100', import.meta.url)
    ],
    props: [
      {
        img: icons.metrics,
        name: '99.1 м2',
      },
      {
        img: icons.floors,
        name: '1 этаж',
      },
      {
        img: icons.rooms,
        name: '3 комнаты',
      },
      {
        img: icons.projectNumber,
        name: '010',
      },
    ]
  },
  {
    id: '007',
    type: 'двухэтажный',
    title: 'Открытие',
    images: [
      new URL('/pics/projects/007/1.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/007/2.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/007/3.webp?width=421&quality=100', import.meta.url)
    ],
    props: [
      {
        img: icons.metrics,
        name: '100.3 м2',
      },
      {
        img: icons.floors,
        name: '2 этажа',
      },
      {
        img: icons.rooms,
        name: '4 комнаты',
      },
      {
        img: icons.projectNumber,
        name: '007',
      },
    ]
  },
  {
    id: '008',
    type: 'одноэтажный',
    title: 'Гости',
    images: [
      new URL('/pics/projects/008/1.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/008/2.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/008/3.webp?width=421&quality=100', import.meta.url)
    ],
    props: [
      {
        img: icons.metrics,
        name: '127.1 м2',
      },
      {
        img: icons.floors,
        name: '1 этаж',
      },
      {
        img: icons.rooms,
        name: '5 комнат',
      },
      {
        img: icons.projectNumber,
        name: '008',
      },
    ]
  },
  {
    id: '009',
    type: 'одноэтажный',
    title: 'Веселье',
    images: [
      new URL('/pics/projects/009/1.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/009/2.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/009/3.webp?width=421&quality=100', import.meta.url)
    ],
    props: [
      {
        img: icons.metrics,
        name: '137.6 м2',
      },
      {
        img: icons.floors,
        name: '1 этаж',
      },
      {
        img: icons.rooms,
        name: '5 комнат',
      },
      {
        img: icons.projectNumber,
        name: '009',
      },
    ]
  },
  //the weird blueprint. Further clarification is required
  {
    id: '012',
    type: 'двухэтажный',
    title: 'Семья',
    images: [
      new URL('/pics/projects/012/1.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/012/2.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/012/3.webp?width=421&quality=100', import.meta.url)
    ],
    props: [
      {
        img: icons.metrics,
        name: '160.9 м2',
      },
      {
        img: icons.floors,
        name: '2 этажа',
      },
      {
        img: icons.rooms,
        name: '4 комнаты',
      },
      {
        img: icons.projectNumber,
        name: '012',
      },
    ]
  },
  {
    id: 'custom',
    type: 'одноэтажный',
    title: 'Индивидуал',
    images: [
      new URL('/pics/projects/custom/1.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/custom/2.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/custom/3.webp?width=421&quality=100', import.meta.url)
    ],
    props: [
      {
        img: icons.metrics,
        name: '193.5 м2',
      },
      {
        img: icons.floors,
        name: '1 этаж',
      },
      {
        img: icons.rooms,
        name: '4 комнаты',
      },
      {
        img: icons.projectNumber,
        name: 'Эксклюзив',
      },
    ]
  },
  {
    id: '006',
    type: 'одноэтажный',
    title: 'Смелость',
    images: [
      new URL('/pics/projects/006/1.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/006/2.webp?width=421&quality=100', import.meta.url),
      new URL('/pics/projects/006/3.webp?width=421&quality=100', import.meta.url)
    ],
    props: [
      {
        img: icons.metrics,
        name: '193.9 м2',
      },
      {
        img: icons.floors,
        name: '1 этаж',
      },
      {
        img: icons.rooms,
        name: '3 комнаты',
      },
      {
        img: icons.projectNumber,
        name: '006',
      },
    ]
  },
]
